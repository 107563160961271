@import "./../../Variables.scss";

.attachments-input-file {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.btn-add-attachments {
  font-size: 12px !important;
  min-width: inherit !important;
  margin-top: 0px !important;
  cursor: pointer;
}

.attachments-modal-spinner {
  text-align: center;
  .spinner-border {
    border-color: map-get($theme-colors, "primary-contrast");
    border-right-color: transparent;
  }
}

.attachments-modal-confirm {
  display: inline-block;
}

.attachments-content-modal {
  background-color: transparent;
  box-shadow: none !important;
}


.attachments-row {
  display: flex;
  align-items: center;
}


.attachments-row-info {
  display: flex;
  justify-content: space-between;
  align-items: center; 
  width: 100%;
}

.list {  
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.actions--delete {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
}

.actions--upload {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.action-button {
  cursor: pointer;  
  height: 24px;
  display: flex;
  align-items: center;
  background-color: map-get($theme-colors, "primary");
  border-radius: 12px;
  margin-left: 12px;
  img {
    margin-left: 24px;
    width: 16px;
  } 
  span {
    text-decoration: none;
    color: map-get($theme-colors, "white");
    font-weight: 500;
    font-size: smaller;
    padding: 0 24px 0 4px;
  }
}
