@import "./../../Variables.scss";

.btn-icon-trash {
    display: inline-block;
    width: 24px;
    background-color: map-get($theme-colors, "primary-contrast");
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
    img {
      margin-left: 4px;
      margin-bottom: 4px;
      width: 16px;
    }
  }