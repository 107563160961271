@import './../../Variables.scss';

.search-add-cont{
    button{
        float: right;
        @media only screen and (max-width: 1196px) {
            &.position-btn-filter{
                margin-top: 20px;
            }
        }
        @media only screen and (max-width: 991px){
          float: inherit;
          width: 100%;
        }
    }
    @media only screen and (max-width: 767px){
        .search-bar{
            margin-bottom: 15px;
            input{
                font-size: initial;
            }
        }
    }
}