@import "./../../Variables.scss";

.description {
    text-align: 'left';
    color: map-get($theme-colors, "primary");
    font-weight: 500;
}

.label {
    color: map-get($theme-colors, "primary");
    font-weight: 500;
}

.error {
    display: flex;
    color: map-get($theme-colors, "danger");
    background-color: map-get($theme-colors, "white");
    font-weight: 600;
}