@import "./../../Variables.scss";

.activity-description {
  &.long {
    white-space: pre-wrap;
  }

  &.short {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.read-less-more {
  color: map-get($theme-colors, "primary");
  margin-top: 4px;
  cursor: pointer;
  text-decoration: underline;
}
