@import './../../Variables.scss';

.btn-lang{
  button{
    border: 0;
    font-weight: 600;
    background-color: transparent;
    &.active,
    &:hover,
    &:active,
    &:focus{
      color: map-get($theme-colors, "primary-contrast");
      box-shadow: none;
      outline: 0;
    }
  }
}