@import "./../../Variables.scss";

.kpi-box {
    background-color: map-get($theme-colors, "light-grey");
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 5px;
}

.kpi-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
}

.kpi-value {
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    text-align: center;
}