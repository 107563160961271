@import './../../Variables.scss';

.freezed{
  width: 112px;
  float: right;
  margin-bottom: 0 !important; 
  font-weight: 600;
  opacity: 0.8;
  span{
    color: map-get($theme-colors, "white") !important;
    font-weight: 600 !important;
    position: absolute;
    right: 65px;
  }
  .btn{
    position: absolute;
    right: 16px;
    top: -8px;
    background-color: map-get($theme-colors, "freezed");
    border: 2px solid map-get($theme-colors, "freezed");
    box-shadow: none;
    margin-top: 0;
    padding: 2px 8px !important;
    margin-right: 3px;
    &:hover{
      background-color: map-get($theme-colors, "freezed");
    }
    &:active,
    &:visited{
      border: 2px solid map-get($theme-colors, "white") !important;
      background-color: map-get($theme-colors, "freezed") !important;
      box-shadow: none;
    }
  }
  &:hover{
    opacity: 1;
  }
  &.freezed-active{
    opacity: 1;
    .btn{
      border: 2px solid map-get($theme-colors, "white") !important;
      background-color: map-get($theme-colors, "freezed") !important;
    }
  }
}
