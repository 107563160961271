@import './../../Variables.scss';

.cont-pagination {
  margin-top: 20px;

  nav {
    background-color: transparent;
    padding: 0;

    ul {
      li {

        a,
        button {
          margin-bottom: 0;
          color: map-get($theme-colors, "primary");
          border: none;
          transition: 0.2s ease-in-out;

          &:hover,
          &:active,
          &:focus {
            background-color: transparent;
            color: map-get($theme-colors, "primary-contrast");
            outline: 0;
            box-shadow: none;
          }

          &::before {
            border: none;
          }
        }
      }
    }
  }

  .page-item {
    &.active {
      .page-link {
        background-color: map-get($theme-colors, "primary");
        border-color: map-get($theme-colors, "primary");
      }
    }
  }
}