@import './../../Variables.scss';

.activity-table{
    margin-bottom: 0;
    tbody{
        tr{
            td{
                &:first-child{
                    font-weight: initial;
                }
                &:last-child{
                    font-weight: initial;
                }
            }
        }
    }
}