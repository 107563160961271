@import './../../Variables.scss';

.rubric-table{
    thead{
        background-color: map-get($theme-colors, "background");
        tr{
            th{
                border: 0;
                color: map-get($theme-colors, "primary-contrast");
                font-size: 18px;
                padding-top: 2px;
                padding-bottom: 2px;
            }
        }
    }
    tbody{
        tr{
            &:first-child{
                td{
                    border-top: 0;
                }
            }
            td{
                color: map-get($theme-colors, "primary");
                &:first-child{
                    font-weight: 700;
                }
                &:last-child{
                    color: map-get($theme-colors, "primary-contrast");
                }
            }
            &:hover{
                background-color: rgba( map-get($theme-colors, "primary"), 0.1);
                cursor: pointer;
            }
        }
    }
}