@import "./../../Variables.scss";

.profile-form {
  .form-control:disabled {
    background-color: map-get($theme-colors, "white");
  }
}

.email-support {
  a {
    color: map-get($theme-colors, "black");
  }
}
