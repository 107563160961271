@import './../../Variables.scss';

nav{
    background-color: map-get($theme-colors, "primary");
    color:  map-get($theme-colors, "white");
    overflow: hidden;
    height: 100%;
    border-top-right-radius: 60px;
    padding: 60px 0px 30px;
    text-align: center;
    a{
        display: block;
        position: relative;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 32px;
        overflow: hidden;
        &:before{
            content: '';
            width: 0;
            height: 100%;
            position: absolute;
            border: 3px solid map-get($theme-colors, "primary-contrast");
            top: 0;
            left: 0;
            transform: translateX(-8px);
            transition: 0.2s ease-in-out;
        }
        svg{
            width: 43px;
            height: 100%;
            transition: 0.2s ease-in-out;
        }
        &:hover,
        &.active,
        &:active{
            svg{
                &.hover-color{
                    fill: map-get($theme-colors, "primary-contrast");
                }
            }
            &:before{
                transform: translateX(0px);
            }
        }
        @media only screen and (max-width: 991px){
            margin-bottom: 20px;
            svg{
                width: 35px;
            }
        }
    }
}

.nav-desktop{
    position: relative;
    width: $menu-size;
    z-index: 10000;
    nav{
        float: left;
        top: 0;
        left: 0;
        height: 100vh;
        width: $menu-size;
    }
    @media only screen and (max-width: 991px){
        display: none; 
     }
}

.scroll-left {
    direction: rtl;
    overflow-y: scroll;
    height: 200px;
    width: 300px;
    border: 1px solid #ccc;
}