@import "./../../Variables.scss";

.modal {
  .search-modal {
    .modal-content {

      .modal-body {
        padding: 15px;

        .form-group {
          height: 280px;
        }

        .form-group, input[type="text"] {
          width: 100%;
        }

        button {
          margin: 0;
          font-size: 15px;

          &.dismiss {
            background-color: map-get($theme-colors, "danger");
          }

          &.done {
            background-color: map-get($theme-colors, "success");
          }
        }
      }
    }
  }
}