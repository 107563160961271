@import "../../Variables.scss";

.custom-select {
    width: 200px;
    background-color: map-get($theme-colors, "white");
    color: map-get($theme-colors, "primary");
    font-weight: 700;
    border: none;
    border-bottom: 2px solid map-get($theme-colors, "primary");
    border-radius: 0;
    margin-bottom: 8px;
  
    option {
        font-weight: 500;
    }
  }
  