@import './../../Variables.scss';

.data-table {
  text-align: center;

  thead {
    background-color: map-get($theme-colors, "background");

    tr {
      th {
        border: 0;
        color: rgba(map-get($theme-colors, "black"), 0.5);
        font-size: 18px;
        font-weight: 500;
      }
    }
  }

  tbody {
    tr {
      &:first-child {
        td {
          border-top: 0;
        }
      }

      td {
        color: map-get($theme-colors, "primary");

        &:first-child {
          font-weight: 700;
        }

        &.close-date {
          color: map-get($theme-colors, "primary-contrast");
          font-weight: 700;
        }

        .badge {
          &.to-do {
            border-radius: 50%;
            background-color: map-get($theme-colors, "danger");
            font-size: 14px;
          }

          &.to-do-green {
            background-color: map-get($theme-colors, "success");
          }
        }
      }

      &:hover {
        background-color: rgba(map-get($theme-colors, "primary"), 0.1);
        cursor: pointer;
      }
    }
  }
}