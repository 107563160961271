@import "./../../Variables.scss";

.action-url {
  margin-right: 7px;
  font-size: 16px;
}

.contact-deals-link {
  float: right;
  font-size: 12px !important;
  background-color: #9dace3 !important;
  color: map-get($theme-colors, "white") !important;
  margin-top: 0px !important;
}

.row-company-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 15px;
}

.btn-company {
  min-width: 46px !important;
  white-space: nowrap;
}

.scrollable {
  overflow-y: auto;
}