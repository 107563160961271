@import './../../Variables.scss';

.editable-label {
  input {
    display: initial;
    width: 100%;
    text-align: right;
    border-radius: 0;
    border: 0;
    border-bottom: 2px solid map-get($theme-colors, "primary");
    font-weight: 600;
    color: map-get($theme-colors, "primary");
    background-color: transparent !important;
    &:active,
    &:focus{
        outline: 0;
        box-shadow: none;
        border-color: rgba(map-get($theme-colors, "primary"), 0.6);
    }
  }

  span {
    cursor: pointer;
  }
  .react-datepicker-wrapper{
    width: 100%;
    .react-datepicker__input-container{
      width: 100%;
    }
  }
}

.react-autosuggest__suggestions-container--open{
  top: 30px;
  right: 0;
  width: 100%;
}