@import "./../../Variables.scss";

.attachment-input-file {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.btn-add-attachment {
  font-size: 12px !important;
  min-width: inherit !important;
  margin-top: 0px !important;
  cursor: pointer;
}

.attachments-modal-spinner {
  text-align: center;
  .spinner-border {
    border-color: map-get($theme-colors, "primary-contrast");
    border-right-color: transparent;
  }
}

.attachment-link {
  text-decoration: underline;
  cursor: pointer;
  font-weight: bold;
  
  white-space: normal;
  word-break: break-all;
  overflow-wrap: break-word;
  max-width: 100%;
}

.attachment-modal-confirm {
  display: inline-block;
}

.attachment-content-modal {
  background-color: transparent;
  box-shadow: none !important;
}


.attachment-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.attachment-row-info {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 75%;
  height: 60px;
  padding-right: 15px;
}

.attachment-row-label {
  display: flex;
  justify-content: flex-end;
  width: 50%;
}