@import './../../Variables.scss';

.root{
  position: relative;
  height: 75px;
}

.container-processbar{
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 7px;
  height: 50px;
  .progressbar{
    counter-reset: step;
    padding-left: 0;
    li{
      float: left;
      width: 16.6%;
      position: relative;
      text-align: center;
      list-style: none;
      font-size: 12px;
      @media only screen and (max-width: 575px){
        font-size: 9px;
      }
      &:hover{
        cursor: pointer;
      }
      &:before{
        content:counter(step);
        counter-increment: step;
        width: 30px;
        height: 30px;
        display: block;
        margin: 0 auto 10px auto;
        border-radius: 50%;
        line-height: 27px;
        color: rgba(map-get($theme-colors, "white"), 0.5);
        text-align: center;
        font-weight: bold;
        font-size: 16px;
      }
      &:first-child:before{
        background: #FFB238;
        border: 2px solid #FFB238;
      }
      &:nth-child(2):before{
        background: #FF6B38;
        border: 2px solid #FF6B38;
      }
      &:nth-child(3):before{
        background: #C75146;
        border: 2px solid #C75146;
      }
      &:nth-child(4):before{
        background: #AD2E24;
        border: 2px solid #AD2E24;
      }
      &:nth-child(5):before{
        background: #81171B;
        border: 2px solid #81171B;
      }
      &:last-child:before{
        background: #540804;
        border: 2px solid #540804;
      }
      &:after{
        content: '';
        position: absolute;
        width:100%;
        height: 2px;
        background: map-get($theme-colors, "darkborder");
        top: 15px;
        left: -50%;
        z-index: -1;
        padding-left: 15px;
      }
      &:first-child{
        &:after{
          content: none;
        }
      }
      
    }
  }
}
    
.progressbar li:first-child.active:before{
  border-color: map-get($theme-colors, "white");
}

.progressbar li.active:after{
  background: map-get($theme-colors, "white");
  
}

 .progressbar li.active:before{
   border-color: map-get($theme-colors, "white") !important;
   color: map-get($theme-colors, "white");
}
