@import './../../Variables.scss';

textarea {
  &.editable-textarea {
    background-color: transparent;
    border: none;
    color: white;
    resize: none;
    overflow: hidden;
    overflow-wrap: break-word;
    font-size: 1.75rem;
    height: 56px;

    .editable {
      background-color: white;
      border: inherit;
      border-color: rgba(map-get($theme-colors, "primary"), 0.6);
    }
  }
}
