@import "./../../Variables.scss";

.user-delete-button {
  flex: auto;

  .btn-trash {
    background-color: #9dace3 !important;
    padding: 6px 12px 7px !important;
    border-radius: 50%;

    img {
      margin-right: 0;
      margin-top: -4px;
      width: 14px;
    }
  }
}
