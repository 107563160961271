@import "./../../Variables.scss";

.custom-modal-padding{
  .modal-content{
    padding: 15px;
    .modal-body{
      padding: 0;
      .cont-grey{
        margin-top: 0;
        &:last-child{
          margin-bottom: 0;
        }
        .cont-todo-buttons{
          padding: 0 16px 16px;
          .btn{
            margin: 0;
            font-size: 15px;
            &.dismiss{
             background-color: map-get($theme-colors, "danger");
            }
            &.done{
             background-color: map-get($theme-colors, "success");
            }
          }
          button:last-child{
           float: right;
          }
         }
      }
    }
  }
}


