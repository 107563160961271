@import './../../Variables.scss';

.modal-dialog{
  max-width: 700px;
}
.modal-content{
  border: 0;
  background-color: map-get($theme-colors, "white");
  -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
  border-radius: 20px;
  .modal-header{
    background-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
    text-align: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    .modal-title{
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 600;
      .badge{
        padding-right: 15px;
        padding-left: 15px;
        border-radius: 20px;
      }
    }
    .close{
      color: map-get($theme-colors, "white");
    }
  }
  .modal-body{
    p{
      color: map-get($theme-colors, "primary");
      text-align: center;
      font-weight: 700;
    }
    .cont-btn-modalform{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .btn{
        font-size: 15px !important;
        &:first-child{
          margin-right: 8px;
        }
      }
      .btn-secondary{
        background-color: map-get($theme-colors, "danger");
      }
    }
    .form-inline{
      margin-bottom: 0;
      .form-description{
        width: 100%;
        margin: 30px 0;
        input{
          width: 100%;
          margin-right: 0 !important;
          margin-left: 0 !important;
        }
        textarea{
          margin: 0px !important;
          height: 125px;
          width: 100%;
        }
      }
      .done-checkbox{
        margin-top: 5px;
        label{
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    @media only screen and (max-width: 575px){
      .form-inline{
        .form-group{
          width: 50%;
          &:first-child{
            padding-right: 8px;
          }
          &:last-child{
            padding-left: 8px;
          }
          &.form-description{
            width: 100%;
          }
        }
      }
    }
  }
  .modal-footer{
    border-top: 0;
    .btn{
      font-size: 15px !important;
    }
    .btn-secondary{
      background-color: map-get($theme-colors, "danger");
    }
  }
}