@import "./../../Variables.scss";

.sales-card {
  .react-datepicker__input-container input,
  input[type="date"] {
    cursor: pointer;
    color: map-get($theme-colors, "primary");
    text-align: right;
    font-weight: bolder;
    border: none;
  }

  .search-value {
    cursor: pointer;
  }

  select.inline-select {
    width: 50%;
    line-height: 0.8;
    height: auto;
  }

  .range-input {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  .range-input::before {
    content: "0";
  }
  
  .range-input::after {
    content: "100";
  }

  .section-attachments {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid map-get($theme-colors, "grey");
  }
}