@import './../../Variables.scss';

.balance-cont{
    background-color: map-get($theme-colors, "primary");
    color: map-get($theme-colors, "white");
    padding: 21px 16px;
    width: 250px;
    border-radius: 20px;
    float: left;
    margin-bottom: 18px;
    margin-right: 18px;
    -webkit-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
    -moz-box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
    box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.12);
    h5{
       font-weight: 700;
    }
    hr{
        border-top: 3px solid map-get($theme-colors, "primary-contrast");
        width: 50px;
        margin-left: 0;
    }
    p{
        text-align: center;
        font-size: 40px;
        line-height: 30px;
        font-weight: 700;
        color: map-get($theme-colors, "primary-contrast");
        margin-bottom: 0;
        i{
            font-size: 18px;
            line-height: 0;
            font-weight: initial;
            color: map-get($theme-colors, "white");
            opacity: 0.5;
        }
    }
    &:last-child{
        margin-right: 0;
    }
    @media only screen and (max-width: 1515px) {
        width: 19.5%;
        margin-right: 1%;
        h5{
            font-size: 17px;
        }
        hr{
            width: 30px;
        }
        p{
            font-size: 28px;
            line-height: 28px;
            i{
                font-size: 15px;
            }
        }
    }
    @media only screen and (max-width: 700px){
       padding: 3% 1%; 
       text-align: center;
       border-radius: 10px;
       h5{
           font-size: 12px;
       }
       hr{
           display: none;
       }
       p{
        font-size: 18px;
        line-height: 18px;
            i{
                font-size: 12px;
            }
        }
    }
}